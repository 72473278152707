<template>
  <div class="maxBox">
    <div class="content">
      <div class="tit">
        <img src="../assets/img/logo.png" />
        <div class="text">
          <span>收银台</span>
          <div class="figure">应付金额：<span>¥9.9</span></div>
        </div>
      </div>
      <div class="payBOX">
        <div class="imgBOX">
          <img src="../assets/img/wxPay.jpg" alt="" /> 微信支付
        </div>
        <div class="qrcodeBox">
          <vue-qr :text="qrUrl" :size="200"></vue-qr>
          <div class="text">
            <p>请使用微信扫描</p>
            <p>二维码以完成支付</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vueQr from "vue-qr";
export default {
  data() {
    return { qrUrl: "" };
  },
  methods: {
    PayQrcode() {
      this.$http
        .post("goodsNativePay", {
          goodsId: "100",
          goodsDescription: "乒乓球的搓球技术",
          amount: 9.9,
        })
        .then((res) => {
          if (res.code == 0) {
            this.qrUrl = res.url;
          }
        });
    },
  },
  created() {
    this.PayQrcode();
  },
  components: {
    vueQr,
  },
};
</script>

<style lang="less" scoped>
.maxBox {
  height: calc(100vh - 100px);
  padding: 100px;
  .content {
    width: 100%;
    padding: 30px 0;
    border: 1px solid #eee;
    box-shadow: 2px 2px 3px #aaaaaa;
    .tit {
      display: flex;
      align-items: center;
      font-size: 30px;
      padding: 0 30px;
      img {
        width: 65px;
        height: 65px;
        margin-right: 10px;
      }
      .text {
        flex: 1;
        display: flex;
        justify-content: space-between;
        div {
          font-size: 20px;
          span {
            font-size: 24px;
          }
        }
      }
    }
    .order {
      height: 80px;
      margin: 10px 0;
      background: #eee;
    }
    .payBOX {
      display: flex;
      align-items: center;
      .imgBOX {
        margin: 0 30px 0 60px;
        display: flex;
        align-items: center;
        img {
          width: 40px;
          height: 40px;
        }
      }
      .qrcodeBox {
        .text {
          width: 130px;
          margin: auto;
          padding: 5px 20px;
          font-size: 14px;
          line-height: 1.5em;
          color: #eee;
          background: #375277;
        }
      }
    }
  }
}
</style>
